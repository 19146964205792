import { useId, useRef, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
const propsForPick = [
    'name',
    'onChange',
    'onBlur',
    'value',
    'rules',
    'undefinedValue',
    'id',
    'label',
    'disabled',
];
/**
 * Hook for compatibility with/without react-hook-form provider and custom state management
 */
export function useField({ name, onChange, onBlur, value, rules, undefinedValue = null, id, label, disabled, }) {
    const generatedId = useId();
    const ctx = useFormContext();
    const { control, formState = {}, watch = (_name) => {
        return value;
    }, } = (ctx || {});
    const valueRef = useRef(value);
    // if we want to get the value at blur, we have to keep it
    const watchedValue = watch(name);
    useEffect(() => {
        valueRef.current = watchedValue || undefinedValue;
    }, [watchedValue, undefinedValue]);
    let defaultValue = name ? undefinedValue : undefined;
    const { field = {}, fieldState = {}, } = name
        ? // eslint-disable-next-line react-hooks/rules-of-hooks -- is the usecase when I don't pass the name to the component at that moment it is necessary not to register the field e.g. Checkbox in CheckboxGroup
            useController({
                name,
                rules,
                disabled,
                defaultValue,
            })
        : {
            field: {
                value: value ?? undefinedValue,
            },
            fieldState: {},
        };
    const error = fieldState.error?.message;
    return {
        error,
        hasError: !!error,
        id: id || generatedId,
        name,
        label,
        disabled,
        value: field.value,
        onBlur: (e) => {
            const event = e ||
                {
                    target: {
                        name,
                        value: valueRef.current,
                    },
                };
            // @ts-ignore
            field.onBlur?.(event);
            onBlur?.(event);
        },
        onChange: (e, ...args) => {
            // @ts-ignore
            const value = e?.target?.value || e;
            const finalValue = value ?? undefinedValue;
            valueRef.current = finalValue;
            if (name != null) {
                field.onChange?.(finalValue);
            }
            // @ts-ignore
            onChange?.(finalValue, name, ...args);
        },
    };
}
